const moment = require('moment');
import { owlCarousel } from "owl.carousel";
/**
 * 単純なサンプル
 * moment.jsを使って現在日付をconsole出力するだけ
 */
$(function () {
    // console.log(moment().format("YYYY-MM-DD"));

    $('.owl-carousel').owlCarousel({
        items: 1,
        loop: true,
        animateOut: 'fadeOut',
        autoplay: true,
        autoplayTimeout: 10000,
        nav: false,
        dots: false,
        mouseDrag: false,
        touchDrag: false,
        autoplayHoverPause: false
    }).on('translate.owl.carousel', function (event) {
        console.log(event.item);
    });


    if ($('.TopPage--Image').length) {
        $(window).scroll(function () {
            const elemOffset = $('.TopPage--Image').offset().top;
            let scrollPos = $(window).scrollTop();
            let wh = $(window).height();
            console.log(elemOffset);

            if (elemOffset < scrollPos + wh) {
                let y = scrollPos + wh - elemOffset;
                $('.TopPage--Image').css('background-position', '0 ' + parseInt(-y / 5) + 'px');
            }
        });
    }
});
