// グローバルに使いたいモジュール,関数はこちらで定義
// 残りはcomponents配下に配置してバンドルする方向性
$(function () {
  let w = $(window).width();
  if (w <= 768) {
    $("body").css({
      "padding-top": $("#Header").outerHeight()
    })
  }
  $(document).on("click", ".SPNav--Opne", function () {
    $(this).toggleClass("Active");
    $("#Nav").toggleClass("Active");
  })
})

$(window).resize(function () {

  let w = $(window).width();
  if (w <= 768) {
    $("body").css({
      "padding-top": $("#Header").outerHeight()
    })
  } else[
    $("body").css({
      "padding-top": 0
    })
  ]
})
